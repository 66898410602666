.p0-jobdetails {
  background: color-base02(1);
  padding: 5%;
  @media (min-width:1120px) {
    padding: 60px;
  }

  &_headline {
    border-bottom: 2px solid color-effect01(1);
    text-align: left !important;
    @include innerBox();
    & .-inner {
      &:after {
        content: '・';
      }
      &:last-child:after {
        content: none;
      }
    }
  }

  &_guidelines {
    background: color-wh(1);
    padding: 5%;
    @media (min-width:1120px) {
      padding: 30px;
    }

    &_item {
      border-bottom: 1px solid color-sub05(1);
      padding-top: 20px;
      padding-bottom: 20px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
      }
    }

  }

}