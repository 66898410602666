.p3-img {
  &.-i01 {
    
  }

  &.-i02 {
    & img {
      display: block;
    }
  }

  &.-i03 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    & .-item {
      display: block;
      width: calc(100% / 2 - 4%);
      max-width: 200px;
      box-sizing: content-box;
      &:first-child {
        padding-right: 4%;
      }
      @media (min-width:600px) {
        width: calc(100% / 3 - 3%);
        max-width: 280px;
        padding-right: 4%;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

}