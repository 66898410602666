@mixin btnsize($pc,$sp) {
  width: $pc;
  @media (min-width:600px) {
    width: $sp;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;
  &_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: color-wh(1);
    line-height: 1.2;
    cursor: pointer;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    @include font-gj(m);
    @extend %hover;
    @media (min-width:600px) {
      padding: 20px 0;
    }
  }
  @if $status == 1 {
    &_inner {
      background: color-base03(1);
      font-size: 1.6rem;
      @media all and (min-width:600px) {
        font-size: 2rem;
      }
    }
  }
  @else if $status == 2 {
    &_inner {
      padding: 20px 0;
      background: color-effect01(1);
      font-size: 2.4rem;
      @media (min-width:600px) {
        padding: 30px 0;
        font-size: 3.6rem;
      }
    }
  }
  @else if $status == 3 {
    &_inner {
      background: color-effect01(1);
      font-size: 1.6rem;
      @media (min-width:600px) {
        font-size: 2rem;
      }
    }
  }

}


.p0-btn1 {
  @include btnsize(100%, 350px);
  max-width: 350px;
  @include btn(1);
  @media (max-width:600px) {
    max-width: 280px;
  }
  @media (min-width:600px) and (max-width:660px) {
    width: 100%;
  }
  &.-type01 {
    @media (min-width:600px) and (max-width:956px) {
      width: 100%;
    }
  }
}

.p0-btn2 {
  width: 100%;
  max-width: 640px;
  position: relative;
  @include btn(2);
  @media (min-width:760px) {
    width: 640px;
  }
  &_inner {
    @media (min-width:940px) {
      background-image: url(../images/common/script_contact.png);
      background-repeat: no-repeat; 
      background-size: 205px auto;
      background-position: 560px -50px;
    }
  }
}

.p0-btn3 {
  @include btnsize(100%, 350px);
  max-width: 350px;
  @include btn(3);
  @media (max-width:600px) {
    max-width: 280px;
  }
  &.-back {
    & .p0-btn3_inner {
      background: color-base03(1);
    }
  }
}