.p3-text {
  & .-em01 {
    font-size: 125%;
    color: color-txt02(1);
    line-height: 1.2;
    @include font-gj(b);
  }
  & .-pk, &.-pk {
    color: color-effect01(1) !important;
  }
  & .-org, &.-org {
    color: color-effect03(1) !important;
  }
  &.-t01 {
    text-align: justify;
  }
  &.-t02 {
    position: relative;
    z-index: 5;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    @media (min-width:800px) {
      font-size: 2.8rem;
    }
  }
  &.-t03 {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.4;
    @media (min-width:800px) {
      font-size: 2.6rem;
    }
  }
  &.-t04 {
    font-size: 1.4rem;
    @media (min-width:800px) {
      font-size: 1.6rem;
    }
  }
  &.-t05 {
    font-size: 1.4rem;
    @media (min-width:800px) {
      font-size: 1.6rem;
    }
  }
  &.-t06 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8;
    @media (min-width:600px) {
      font-size: 1.8rem;
      line-height: 2;
    }
    @media (min-width:800px) {
      font-size: 2.2rem;
    }
  }
  &.-t07 {
    line-height: 1.5;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.-t08 {
    font-size: 80%;
    line-height: 1.7;
  }
  &.-t09 {
    padding-bottom: 20px;
    border-bottom: 2px solid color-effect01(1);
  }
  &.-t10 {
    padding-bottom: 20px;
  }

  &.-list01 {
    text-align: justify;
    @include listIndent(2.4,2);
    & .-item {
      line-height: 1.75;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.-ttl01 {
    font-size: 4.5vw;
    color: color-wh(1);
    font-weight: 700;
    text-align: center;
    line-height: 1;
    @media (min-width:400px) {
      font-size: 2rem;
    }
    @media (min-width:800px) {
      font-size: 2.8rem;
    }
    & .-ttlInner01 {
      width: 100%;
      display: inline-block;
      background: color-effect02(1);
      border-radius: 100px;
      line-height: 1;
      padding: 15px 5% 20px;
      position: relative;
      z-index: 5;
      @media (min-width:600px) {
        width: inherit;
        padding: 15px 8% 20px;
      }
      @media (min-width:600px) {
        padding: 15px 45px 20px;
      }
    }
  }
  &.-ttl02 {
    font-size: 2rem;
    color: color-effect03(1);
    line-height: 1.35;
    @include font-gj(b);
    @media (min-width:520px) {
      text-align: center;
    }
    @media (min-width:600px) {
      font-size: 2.4rem;
    }
    @media (min-width:820px) {
      line-height: 1.6;
      font-size: 2.8rem;
    }
    & .br {
      @media (max-width:980px) {
        display: none;
      }
    }
  }

  &.-ttl03 {
    font-size: 90%;
    line-height: 1.3;
    color: color-txt02(1);
    @include font-gj(b);
  }

}