.p2-ftinfo {
  font-size: 1.4rem;
  line-height: 1.6;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width:700px) {
    flex-flow: row nowrap;
  }
  &_item {
    @media (min-width:700px) {
      padding-right: 6%;
    }
    @media (min-width:800px) {
      padding-right: 8%;
    }
    &:last-child {
      padding-right: 0;
    }
    &.-item1 {
      
    }
    &.-item2 {
      padding-top: 15px;
      @media (min-width:700px) {
        padding-top: 0;
      }
    }
  }
  &_txt {
    margin-bottom: 10px;
    &.-logo-kango {
      width: 246px;
      max-width: 246px;
    }
    &.-logo-olivier {
      width: 122px;
      max-width: 122px;
    }
    &.-bold {
      font-size: 1.5rem;
      font-weight: 700;
    }
    & .-link {
      border-bottom: 1px solid color-txt01(1);
      &.-link2 { color: color-base04(1); }
    }
    & .-maplink {
      font-weight: 700;
      color: color-wh(1);
      background: color-effect01(1);
      font-size: 1.2rem;
      padding: 0 3px 1px;
      margin-left: 5px;
    }
  }
}