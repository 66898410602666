.p1-logo {
  width: calc(100% - #{$drawer-space}*2 );
  display: block;
  position: relative;
  z-index: 1200;
  @media (min-width:800px) {
    width: 100%;
  }
  &_inner {
    width: 90%;
    margin: 0 auto;
    position: relative;
    @media (min-width:1120px) {
      width: 1000px;
    }
  }
  &_item {
    display: block;
    &.-olivier {
      width: 100px;
      position: absolute;
      top: 10px;
      left: 0;
      display: none;
      @media (min-width:800px) {
        display: block;
      }
    }
    &.-kunitachimedical {
      width: 380px;
      margin: 19px auto 17px;
      display: none;
      @media (min-width:800px) {
        display: block;
      }
    }
    &.-comp {
      width: 140px;
      position: fixed;
      top: 10px;
      left: 5%;
      @media (min-width:800px) {
        width: 176px;
        top: 8px;
      }
    }
  }
  &_img {
    display: block;
  }
}