.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  @media all and (min-width:600px) {
    padding: 60px 0;
  }
  &.-pad0 {
    padding: 0;
  }
  &.-largeSpace {
    padding: 40px 0;
    @media all and (min-width:600px) {
      padding: 80px 0;
    }
  }
  &.-gray {
    background: color-base02(1);
  }
  &_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    @media all and (min-width:1120px) {
      width: 1000px;
    }
    &.-wide {
      @media all and (min-width:1180px) {
        width: 1120px;
      }
    }
  }
  &.-last {
    padding-bottom: 60px;
    @media all and (min-width:600px) {
      padding-bottom: 120px;
    }
  }
}

.l-wrap {
  &.-front {
    background-image: url(../images/common/flower01.png), url(../images/common/flower02.png), url(../images/common/flower03.png);
    background-size: 160px, 80px, 110px;
    background-position: 115% 240px, 12px 440px, 100% 610px;
    background-repeat: no-repeat, no-repeat, no-repeat;
    @media (min-width:500px) {
      background-size: 200px, 110px, 130px;
      background-position: 115% 270px, 12px 460px, 100% 580px;
    }
    @media (min-width:600px) {
      background-size: 200px, 110px, 160px;
      background-position: 115% 380px, 12px 680px, 100% 800px;
    }
    @media (min-width:800px) {
      background-size: 240px, 132px, 203px;
      background-position: 115% 148px, -30px 520px, 100% 800px;
      background-repeat: no-repeat, no-repeat;
    }
    @media (min-width:800px) {
      background-size: 240px, 132px, 203px;
      background-position: 115% 148px, -30px 520px, 95% 1070px;
    }
    @media (min-width:1190px) {
      background-size: 310px, 202px, 203px;
      background-position: 110% 166px, -30px 367px, 90% 1070px;
    }
    @media (min-width:1440px) {
      background-position: calc(50% - 500px + 1140px) 166px, calc(50% - 500px - 90px ) 367px, calc(50% - 500px + 920px) 1070px;
    }
  }

  &.-sub {
    background-image: url(../images/common/flower04.png), url(../images/common/flower05.png);
    background-size: 20vw, 11vw;
    background-position: 109% 43px, -2% 20vw;
    background-repeat: no-repeat, no-repeat;
    @media (min-width:600px) {
      background-position: 109% 26vw, -2% 20vw;
    }
    @media (min-width:800px) {
      background-size: 20vw, 11vw;
      background-position: 109% 39vw, -2% 270px;
    }
    @media (min-width:950px) {
      background-position: 109% 360px, -2% 270px;
    }
    @media (min-width:1120px) {
      background-size: 255px, 165px;
      background-position: calc(50% - 500px + 1030px) 350px, calc(50% - 500px - 58px ) 270px;
    }
  }

}