.p3-headline {
  text-align: center;
  color: color-txt02(1);
  @include font-gj(b);
  &.-page01 {
    display: none;
    font-size: 2.4vw;
    margin-bottom: 10px;
    line-height: 1;
    position: relative;
    z-index: 10;
    @include font-gj(m);
    @media (min-width:460px) {
      display: inline-block;
    }
    @media (min-width:640px) {
      margin-bottom: 15px;
    }
    @media (min-width:800px) {
      font-size: 2.2rem;
      margin-bottom: 25px;
    }
  }
  &.-page02 {
    font-size: 8vw;
    line-height: 1;
    
    @media (min-width:460px) {
      font-size: 6.5vw;
      margin-bottom: 10px;
    }
    @media (min-width:640px) {
      font-size: 5.5vw;
      margin-bottom: 15px;
    }
    @media (min-width:800px) {
      font-size: 5.4rem;
    }
    @media (min-width:900px) {
      margin-bottom: 20px;
    }
  }

  &.-cnt  {
    margin-top: 20px;
    margin-bottom: 40px;
    line-height: 1.2;
    position: relative;
    font-size: 4.2rem;
    font-weight: normal;
    @media (min-width:600px) {
      margin-top: 40px;
      font-size: 6rem;
      margin-bottom: 80px;
    }
  }
  &.-cnt2 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 3.6rem;
    @media (min-width:600px) {
      margin-top: 20px;
      font-size: 4.6rem;
      margin-bottom: 30px;
    }
    @media (min-width:800px) {
      margin-bottom: 60px;
    }
  }
  &.-cnt3 {
    margin-top: 30px;
    @media (min-width:600px) {
      margin-top: 8vw;
    }
    @media (min-width:900px) {
      margin-top: 70px;
    }
  }
  & .-main {
    position: relative;
    z-index: 10;
  }
  & .-main2 {
    position: relative;
    z-index: 10;
  }
  & .-small {
    font-size: 1.6rem;
    margin-bottom: 5px;
    position: relative;
    z-index: 10;
    @include font-gj(m);
    @media (min-width:600px) {
      font-size: 2.2rem;
      margin-bottom: 17px;
    }
  }
  & .-script, &.-script {
    & img { display: block; }
    position: absolute;
    z-index: 0;
    &.-policy {
      width: 40vw;
      max-width: 314px;
      min-width: 160px;
      bottom: -13vw;
      left: 0;
      right: 0;
      margin: auto;
      @media (min-width:800px) {
        width: 314px;
        bottom: -108px;
      }
    }
    &.-recruit {
      width: 24vw;
      max-width: 183px;
      min-width: 120px;
      z-index: 1;
      top: 30px;
      left: 7%;
      @media (min-width:800px) {
        width: 183px;
        top: 60px;
        left: calc(5% + 40px);
      }
      @media (min-width:1120px) {
        top: 60px;
        left: -395px;
        right: 360px;
        margin: auto;
      }
    }
    &.-contact {
      width: 120px;
      max-width: 205px;
      top: -34px;
      right: 1.5%;
      @media (min-width:600px) {
        width: 180px;
        right: 5%;
      }
      @media (min-width:940px) {
        width: 205px;
        top: 10px;
        left: 0;
        right: -688px;
        margin: auto;
      }
    }
    &.-joinus {
      width: 30vw;
      max-width: 269px;
      min-width: 130px;
      right: 3vw;
      bottom: -9vw;
      @media (min-width:600px) {
        bottom: -11vw;
      }
      @media (min-width:800px) {
        right: 3vw;
        bottom: -65px;
      }
      @media (min-width:1060px) {
        width: 269px;
        right: 100px;
        bottom: -65px;
      }
    }
    &.-jobs {
      width: 20vw;
      max-width: 160px;
      min-width: 108px;
      margin: auto;
      top: -30px;
      left: -164px;
      right: 0;
      @media (min-width:600px) {
        top: -8vw;
      }
      @media (min-width:900px) {
        width: 160px;
        top: -61px;
      }
    }
  }
  
  &.-sub {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 30px;
    @media (min-width:600px) {
      font-size: 4rem;
      margin-bottom: 60px;
    }
  }
  &.-sub2 {
    line-height: 1.6;
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 20px;
    @media (min-width:600px) {
      font-size: 3rem;
      margin-bottom: 40px;
    }
    @media (min-width:800px) {
      margin-bottom: 80px;
      font-size: 4rem;
      line-height: 2.1;
    }
  }

}