.p0-hero {
  padding-bottom: 0;
  @media all and (min-width:800px) {
    padding-bottom: 20px;
  }
  &.-front {
    & .p0-hero_content {
      @media all and (min-width:600px) {
        width: calc(90% + 40px);
      }
      @media all and (min-width:1120px) {
        width: 1040px;
      }
    }
  }
  &_slider {
    position: relative;
    padding: 0 0 20px;
    @media (min-width:600px) {
      padding: 15px 20px 0;
    }
    &_item {
      position: relative;
      & img { display: block; }
      & .-txt {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        @media (min-width:600px) {
          display: block;
        }
      }
    }
    &_dots {
      position: absolute;
      right: 3%;
      bottom: 33px;
      @media (min-width:600px) {
        right: 0;
      }
      & ul {
        width: 10px;
      }
      & li {
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 100px;
        background: color-wh(.8);
        &:last-child {
          margin-bottom: 0;
        }
        @media (min-width:600px) {}
      }
      & button {
        width: 10px;
        height: 10px;
        background: color-effect01(.4);
        text-indent: -9999px;
        border-radius: 100px;
      }
      & .slick-active button {
        background: color-effect01(1);
      }
    }
  }

  &.-sub {
    .p0-hero_img { display: block; }
  }

  &_wrap {
    display: flex;
    align-items: center;
    padding: 15px 0 0 0;
    @media (min-width:600px) {
      padding: 15px 0 0 0;
    }
  }

  &_headline {
    & img { display: block; }
    &.-front {
      width: 90%;
      max-width: 300px;
      display: block;
      margin: auto;
      @media (min-width:600px) {
        display: none;
      }
    }
    &.-page {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      line-height: 1;
    }
  }

}