// svg size settings
@mixin svgSize($svg_size_w, $svg_size_h) {
  width: $svg_size_w + px;
  height: $svg_size_h + px;
}

@mixin icon-spot($status) {
  @if $status == 'maplink' {
    fill: color-wh(1);
    @include svgSize (12,16);
  } @else if $status == '' {
    
  }
}

@mixin icon-arrow($status) {
  @if $status == 'recruitpiece' {
    fill: color-effect01(1);
    @include svgSize (11,10);
  } @else if $status == '' {
    
  }
}


// 外部リンクicon svg
@mixin icon-external() {
  fill: color-effect01(1);
  @include svgSize (14,14);
}
.externalIcon, %externalIcon {
  padding: 0 5px;
  @include icon-external();
}