.p0-decoration {
  display: block;
  & img { display: block; }

  &.-bee01 {
    width: 54px;
    display: block;
    position: absolute;
    left: inherit;
    right: 6vw;
    bottom: inherit;
    top: 80px;
    transform: scale(-1, 1);
    @media (min-width:500px) {
      left: 47vw;
      right: inherit;
    }
    @media (min-width:700px) {
      width: 60px;
      top: inherit;
      bottom: 50px;
      right: 20%;
      left: inherit;
    }
  }

  &.-bee02 {
    width: 38px;
    display: block;
    position: absolute;
    left: 5vw;
    top: inherit;
    bottom: 35px;
    @media (min-width:600px) {
      width: 48px;
      left: 20vw;
      top: 0;
      bottom: inherit;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
    }
  }

  &.-bee03 {
    width: 36px;
    position: absolute;
    top: -24px;
    right: 25%;
    transform: scale(-1, 1);
    @media (min-width:600px) {
      width: 48px;
      top: -24px;
      right: 250px;
    }
    @media (min-width:940px) {
      width: 57px;
      right: 0;
      left: 400px;
      margin: auto;
    }
  }

  &.-bee04 {
    width: 36px;
    position: absolute;
    bottom: 0;
    left: 3%;
    @media (min-width:600px) {
      width: 48px;
      top: 28px;
      left: 3%;
    }
    @media (min-width:800px) {
      width: 57px;
      top: 74px;
      right: 0;
      left: -520px;
      margin: auto;
    }
  }
  &.-bee05 {
    width: 38px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: inherit;
    margin: auto;
    @media (min-width:600px) {
      width: 48px;
      left: 3%;
      right: inherit;
      top: inherit;
      bottom: 5vw;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
    }
    @media (min-width:1120px) {
      left: calc(50% - 500px - 30px );
    }
  }

  &.-leaf {
    width: 110px;
    position: absolute;
    left: -5%;
    bottom: -20px;
    @media (min-width:600px) {
      left: -4%;
    }
    @media (min-width:800px) {
      width: 131px;
      left: 4.5%;
    }
    @media (min-width:1120px) {
      left: calc(50% - 1010px / 2);
    }
  }

}