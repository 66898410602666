.p0-separatebox {
  width: 100%;
  background-color: color-base02(1);
  display: flex;
  flex-flow: row wrap;
  &_item {
    width: 100%;
    padding: 5%;
    text-align: center;
    @media (min-width:600px) {
      width: 50%;
    }
    @media (min-width:800px) {
      padding: 40px;
      text-align: center;
    }
  }
  &.-recruit .p0-separatebox_item.-img {
    height: 60vw;
    background: url(../images/front/img02.jpg) center top / cover no-repeat;
    @media (min-width:600px) {
      height: inherit;
    }
  }
}