.p0-officebox {
  padding: 5%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  @media (min-width:1120px) {
    padding: 60px;
  }
  &-headline {
    text-align: left !important;
    border-bottom: 2px solid;
    width: 100%;
    padding: 0 1vw 15px 1vw;
    @media (min-width:840px) {
      width: calc(100% - 340px);
      padding: 0 .5vw 30px .5vw;
    }
    @media (min-width:920px) {
      padding: 0 20px 30px 20px;
    }
  }

  &_info {
    width: 100%;
    @media (min-width:840px) {
      width: 340px;
    }
    padding: 5%;
    background: color-wh(1);
    @media (min-width:800px) {
      padding: 30px;
    }
    &_item {
      line-height: 1;
      border-bottom: 1px solid color-sub05(1);
      padding: 20px 5px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
      & .sp {
        display: none;
        @media (min-width:840px) {
          display: inline-block;
        }
      }
      & .-address {
        line-height: 1.8;
        margin-top: -5px;
      }
      & .-gj {
        @include font-gj(b);
        color: color-txt02(1);
      }
      & .-label {
        font-size: 88%;
        margin-bottom: 15px;
        @include font-gj(m);
      }
      & .-text {
        
      }
      & .-maplink {
        width: 100%;
        max-width: 300px;
        border-radius: 100px;
        line-height: 1;
        margin-top: 10px;
        transition: all .3s ease-in-out;
        &.-pk {
          background: color-effect01(1);
          &:hover, &:focus {
            background: color-effect02(1);
          }
        }
        &.-org  {
          background: color-effect02(1);
          &:hover, &:focus {
            background: color-effect01(1);
          }
        }
        & a {
          display: block;
          width: 100%;
          padding: 5px 3px 7px;
          font-size: 90%;
          text-align: center;
          color: color-wh(1);
          letter-spacing: .06rem;
          @include font-gj(m);
        }
      }
      & .-spotIcon {
        display: inline-block;
        padding-right: 3px;
        vertical-align: -2px;
        box-sizing: content-box;
        @include icon-spot('maplink');
      }
    }
  }

  &_wrap {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;
    @media all and (min-width:840px) {
      padding-bottom: 60px;
      margin-bottom: 40px;
    }
    @media all and (min-width:900px) {
      margin-bottom: 100px;
    }
    @media all and (min-width:1180px) {
      padding-bottom: 132px;
    }
  }

  &_img {
    width: 100%;
    display: block;
    @media (min-width:840px) {
      display: none;
    }
    & img { display: block; }
  }

  &_floatingImg {
    & img { display: block; }
    display: none;
    position: absolute;
    @media (min-width:840px) {
      display: block;
      width: calc(100% - 340px - 10%);
      left: 0;
      bottom: 0;
    }
    @media (min-width:1000px) {
      width: calc(100% - 340px - 15%);
      left: 5%;
    }
    @media (min-width:1120px) {
      width: calc(100% - 520px);
      left: 60px;
    }
    &.-even {
      left: inherit;
      @media (min-width:840px) {
        right: 0;
      }
      @media (min-width:1000px) {
        right: 5%;
      }
      @media (min-width:1120px) {
        right: 60px;
      }
    }
  }


  &.-kunitachi {
    background: color-base05(1);
    & .p0-officebox-headline {
      border-color: color-effect01(1);
    }
    & .p0-officebox_info_item .-label {
      color: color-effect01(1);
    }
  }
  &.-bunji {
    background: color-base06(1);
    & .p0-officebox-headline {
      border-color: color-effect02(1);
    }
    & .p0-officebox_info_item .-label {
      color: color-effect02(1);
    }
  }
  &.-kokin {
    background: color-base07(1);
    & .p0-officebox-headline {
      border-color: color-effect04(1);
    }
    & .p0-officebox_info_item .-label {
      color: color-effect04(1);
    }
  }
  &.-hiro {
    background: color-base08(1);
    & .p0-officebox-headline {
      border-color: color-base03(1);
    }
    & .p0-officebox_info_item .-label {
      color: color-base03(1);
    }
  }

  &.-even {
    .p0-officebox-headline {
      @media (min-width:840px) {
        padding-left: 5%;
        order: 2;
      }
      @media (min-width:1120px) {
        padding-left: 60px;
      }
    }
    .p0-officebox_info {
      @media (min-width:840px) {
        order: 1;
      }
    }
  }

}