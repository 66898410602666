.p5-form {
  width: 100%;
  max-width: 600px;
  margin: auto;
  &_input {
    background-color: color-base02(1);
    padding: 3px 5%;
    font-size: 1.6rem;
    margin-bottom: 15px;
    @media all and (min-width:600px) {
      padding: 8px 15px;
      margin-bottom: 30px;
    }
    &:focus {
      border: 2px solid color-bk(.5);
    }
    &.-txt {
      width: 100%;
    }
    &.-check {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      vertical-align: 3px;
    }
  }
  & .-link {
    margin-bottom: 15px;
    & a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: color-wh(1);
      background: color-effect02(1);
      border-radius: 100px;
      @include font-gj(m);
      @media all and (min-width:600px) {
        width: 310px;
        max-width: 310px;
        padding: 10px 20px;
        font-size: 1.7rem;
      }
    }
  }
  &_btn {
    margin: 0 10px;
  }
  &_btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 !important;
    border-bottom: none !important;
    @media all and (min-width:600px) {
      margin: 60px 0 !important;
    }
  }
}

.mwform-checkbox-field-text {
  vertical-align: 5px;
  padding-left: 5px;
}
.mwform-checkbox-field {
  line-height: 1;
}

.mw_wp_form .error {
  display: block;
  color: color-effect01(1) !important;
  margin: -20px 0 30px;
  font-size: 90%;
  font-weight: 700;
}

.mw_wp_form_input {
  & .p5-form_btn.-back, & .p5-form_btn.-submit, & .p5-form_btn.-top { display: none; }
  & .p5-form_thx { display: none; }
}

.mw_wp_form_confirm {
  & .p5-form_btn.-confirm, & .p5-form_btn.-top { display: none; }
  & .error { display: none; }
  & .p3-text.-ttl03 {
    @include innerBox();
  }
  & .p3-text.-ttl03 ~ div {
    margin-bottom: 15px;
    padding-bottom: 15px;
    line-height: 1.5;
    border-bottom: 1px solid color-sub05(1);
    @include innerBox();
    @media all and (min-width:600px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    &:last-child {
      border: none;
    }
  }
  & .formarea-privacy-wrap { display: none; }
  & .p5-form_thx { display: none; }
}

.mw_wp_form_complete {
  & .p5-form_btn.-back, & .p5-form_btn.-confirm, & .p5-form_btn.xzssxsaazaa-submit { display: none; }
  & ~ .p5-form_intro { display: none; }
}