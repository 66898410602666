.p1-fixBtmNav {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: color-wh(1);
  &_item {
    width: calc(100% / 3);
    transition: all .3s ease-in-out;
    &.-kunitachi {
      background: color-sub01(1);
      &:hover, &:focus {
        background: color-effect01(1);
      }
    }
    &.-bunji {
      background: color-sub02(1);
      &:hover, &:focus {
        background: color-effect02(1);
      }
    }
    &.-kokin {
      background: color-sub03(1);
      &:hover, &:focus {
        background: color-effect04(1);
      }
    }
  }
  &_itemInner {
    width: 100%;
    display: block;
    padding: 8px 0;
    @media (min-width:600px) {
      padding: 10px 0;
    }
    @media (min-width:900px) {
      padding: 15px 0;
    }
  }
  &_itemImg {
    display: block;
    margin: auto;
    width: auto;
    height: 6.3vw;
    max-height: 50px;
    @media (min-width:600px) {
      height: 5.6vw;
    }
    @media (min-width:900px) {
      height: 50px;
    }
  }
}